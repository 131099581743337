import { render, staticRenderFns } from "./Database.vue?vue&type=template&id=0cf6c533&"
import script from "./Database.vue?vue&type=script&lang=js&"
export * from "./Database.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports